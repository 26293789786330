
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { InsuranceClaimEventClient } from '@/services/Services';
import DocumentManagement from '@/components/documentsManagement.vue';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        DocumentManagement
    }
})
export default class InsuranceClaimModal extends Vue {

    @Prop() vanIdentifier: string;
    @Prop() callback: any;

    step: number = 1;
    
    eventIdentifier: string = "";
    eventDate: string = "";
    eventDescription: string = "";
    eventNote: string = "";

    rndPhotos: number = 0;
    photos: OM.UrlAttachment[] = [];

    rndCai: number = 0;
    caiPhotos: OM.UrlAttachment[] = [];

    created() {
        this.eventDate = new Date().toISOString();
    }

    getPhotos() {
        InsuranceClaimEventClient.getAllPhotos(this.eventIdentifier)
        .then(x => {
            this.photos = x;
            this.rndPhotos = Math.random();
        })
    }
    addPhotoFile(newPhotoFile: File) {
        if(!newPhotoFile)
            return;

        InsuranceClaimEventClient.updatePhotoFile(newPhotoFile, this.eventIdentifier, "")
        .then(x => {
            this.getPhotos();
        })
    }
    editPhotoFile(editPhotoFile: VM.EditFile) {
        if(!editPhotoFile.file)
            return;

        InsuranceClaimEventClient.updatePhotoFile(editPhotoFile.file, this.eventIdentifier, editPhotoFile.fileIdentifier)
        .then(x => {
            this.getPhotos();
        })
    }

    getCaiFiles() {
        InsuranceClaimEventClient.getAllCAIFiles(this.eventIdentifier)
        .then(x => {
            this.caiPhotos = x;
            this.rndCai = Math.random();
        })
    }
    addCaiFile(newCaiFile: File) {
        if(!newCaiFile)
            return;

        InsuranceClaimEventClient.updateCAIFile(newCaiFile, this.eventIdentifier, "")
        .then(x => {
            this.getCaiFiles();
        })
    }
    editCaiFile(editCaiFile: VM.EditFile) {
        if(!editCaiFile.file)
            return;

        InsuranceClaimEventClient.updateCAIFile(editCaiFile.file, this.eventIdentifier, editCaiFile.fileIdentifier)
        .then(x => {
            this.getCaiFiles();
        })
    }

    get saveDisabled() {
        return !this.eventDate || !this.eventDescription;
    }

    save() {
        var loggedUserId = store.state.loggedUser.identifier;
        InsuranceClaimEventClient.create(this.eventDate, this.eventDescription, loggedUserId, this.vanIdentifier)
        .then(x => {
            this.eventIdentifier = x;
            this.step = 2;
        })
    }

    saveNote() {
        InsuranceClaimEventClient.addNotes(this.eventIdentifier, this.eventNote)
        .then(x => {
            this.callback();
        })
    }

}
