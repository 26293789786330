import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-90c0ce7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-end" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "tableContainer mt-4" }
const _hoisted_4 = {
  key: 0,
  class: "table mt-3"
}
const _hoisted_5 = { class: "d-block pb-0" }
const _hoisted_6 = { class: "d-block pt-0" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "text-decoration-underline"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "formContainer mt-5" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  key: 0,
  class: "d-block"
}
const _hoisted_15 = {
  key: 1,
  class: "d-block"
}
const _hoisted_16 = { class: "col-6" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = {
  key: 0,
  class: "d-block"
}
const _hoisted_19 = {
  key: 1,
  class: "d-block"
}
const _hoisted_20 = { class: "col-6" }
const _hoisted_21 = { class: "form-group" }
const _hoisted_22 = { class: "d-block" }
const _hoisted_23 = { class: "col-6" }
const _hoisted_24 = { class: "form-group" }
const _hoisted_25 = {
  key: 0,
  class: "d-block"
}
const _hoisted_26 = {
  key: 1,
  class: "d-block"
}
const _hoisted_27 = ["disabled"]
const _hoisted_28 = {
  key: 1,
  class: "btn btn-tertiary text-uppercase w-100 mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_form_field, {
        type: "date",
        labelKey: "Data inizio",
        labelValue: "Data inizio",
        placeholderKey: "Data inizio",
        placeholderValue: "Data inizio",
        required: true,
        modelValue: _ctx.startDate,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event)),
        class: "w-100 mb-0 me-2"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_form_field, {
        type: "date",
        labelKey: "Data fine",
        labelValue: "Data fine",
        placeholderKey: "Data fine",
        placeholderValue: "Data fine",
        required: true,
        modelValue: _ctx.endDate,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDate) = $event)),
        class: "w-100 mb-0"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("button", {
      class: "btn btn-primary w-100 mt-3",
      disabled: _ctx.updateButtonDisabled,
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateTable()))
    }, [
      _createVNode(_component_localized_text, {
        localizedKey: "Filtra",
        text: "Filtra"
      })
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.loaded)
        ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.period, (date, index) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: index,
                  class: _normalizeClass((_ctx.today.getDate() == date.getDate() && _ctx.today.getMonth() == date.getMonth()) ? 'selected' : '')
                }, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$filters.date(date, "DD/MM/YYYY")), 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$filters.date(date, "dddd")), 1)
                ], 2))
              }), 128))
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedRis, (user, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.period, (date, index) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: index,
                    class: "py-3",
                    onClick: ($event: any) => (_ctx.getSelectedWorkShiftDay(user, date))
                  }, [
                    (_ctx.getWorkShiftDay(user, date))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.getWorkShiftDay(user, date).workShiftType.name), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_9, "---"))
                  ], 8, _hoisted_7))
                }), 128))
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_localized_text, {
              type: "label",
              localizedKey: "Data selezionata",
              text: "Data selezionata"
            }),
            (_ctx.selectedWorkShiftDay.workShiftType)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$filters.date(_ctx.selectedWorkShiftDay.day, "DD/MM/YYYY")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_15, "--"))
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_localized_text, {
              type: "label",
              localizedKey: "Tipologia",
              text: "Tipologia",
              class: "mb-0"
            }),
            _createVNode(_component_localized_text, {
              type: "label",
              localizedKey: "turno",
              text: "turno"
            }),
            (_ctx.selectedWorkShiftDay.workShiftType)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.selectedWorkShiftDay.workShiftType.name), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_19, "--"))
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_localized_text, {
              type: "label",
              localizedKey: "Magazzino",
              text: "Magazzino"
            }),
            _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.profile.headquarter.text), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _createVNode(_component_localized_text, {
              type: "label",
              localizedKey: "Sigla Van",
              text: "Sigla Van"
            }),
            (_ctx.selectedWorkShiftDay.workShiftType && _ctx.selectedWorkShiftDay.vanSigla != 'DEFAULT')
              ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.selectedWorkShiftDay.vanSigla), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_26, "--"))
          ])
        ])
      ]),
      (_ctx.selectedWorkShiftDay.workShiftType && _ctx.selectedWorkShiftDay.workShiftType.score > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-primary text-uppercase w-100 mt-2",
            disabled: _ctx.updateButtonDisabled,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.doAnInsuranceClaim()))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Segnala sinistro",
              text: "Segnala sinistro"
            })
          ], 8, _hoisted_27))
        : (_openBlock(), _createElementBlock("button", _hoisted_28, [
            _createVNode(_component_localized_text, {
              localizedKey: "Contattaci",
              text: "Contattaci"
            })
          ]))
    ])
  ], 64))
}