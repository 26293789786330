
import { Options, Vue } from 'vue-class-component';
import { BaseUserClient, WorkShiftDayClient } from '@/services/Services';
import { showNotification } from '@/utils';
import InsuranceClaimModal from '../modals/insuranceClaimModal.vue';
import * as OM from '@/Model';
import store from '@/store';

@Options({
    components: {}
})
export default class Homepage extends Vue {

    today: Date = new Date();
    startDate: string = null;
    endDate: string = null;

    period: Date[] = [];
    ris: OM.WorkShiftDayVm[] = [];
    selectedWorkShiftDay: any = {};
    profile: OM.EmployeePersonalAreaVM = new OM.EmployeePersonalAreaVM();

    loaded: boolean = false;

    created() {
        BaseUserClient.getFullProfileInfo()
        .then(x => {
            this.profile = x;

            this.startDate = new Date().toISOString();
            
            var dayOfWeek = this.today.getDay();
            var daysUntilNextSunday = (7 - dayOfWeek) % 7;

            if (daysUntilNextSunday === 0) {
                daysUntilNextSunday = 7;
            }

            var nextSunday = new Date(this.today);
            nextSunday.setDate(this.today.getDate() + daysUntilNextSunday);

            this.endDate = nextSunday.toISOString();
            this.updateTable();
        })
    }

    mounted() {
        setTimeout(() => {
            this.getSelectedWorkShiftDay(this.groupedRis[store.state.loggedUser.identifier], this.today);
        }, 500);
    }

    get updateButtonDisabled() {
        return !this.startDate || !this.endDate;
    }

    updateTable() {
        WorkShiftDayClient.getWorkShiftDaysByEmployeeIdAndDateRangeAndHeadquarters(this.profile.employeeIdentifier ,this.startDate, this.endDate, this.profile.headquarter.identifier)
        .then(x => {
            this.getDates();
            this.ris = x;

            setTimeout(() => {
                this.loaded = true;
            }, 300);
        })
    }

    getDates() {
        this.period = [];
        var endDate = new Date(this.endDate)
        var currentDate = new Date(this.startDate);

        while (currentDate.getDate() <= endDate.getDate()) {
            this.period.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
    }

    get groupedRis() {
        return this.ris.reduce((accumulator, currentValue) => {
            if (!accumulator[currentValue.employeeId]) {
                accumulator[currentValue.employeeId] = [];
            }

            accumulator[currentValue.employeeId].push(currentValue);
            return accumulator;
        }, {});
    }

    getWorkShiftDay(user: any, date: Date) {
        return (user.filter(x => new Date(x.day).getDate() == date.getDate() && new Date(x.day).getMonth() == date.getMonth()))[0];
    }

    getSelectedWorkShiftDay(user: any, date: Date) {
        this.selectedWorkShiftDay = this.getWorkShiftDay(user, date);
    }

    doAnInsuranceClaim() {
        this.$opModal.show(InsuranceClaimModal, {
            vanIdentifier: this.profile.van.identifier,
            callback: () => {
                this.$opModal.closeLast();
                window.scrollTo(0, 0);

                showNotification(this.$localizationService.getLocalizedValue("Successo", "Successo"), this.$localizationService.getLocalizedValue("Sinistro segnalato con successo", "Sinistro segnalato con successo"));
            }
        })
    }

}
